import React from "react";
import Layout from "../../components/layout";
import Hero from "../../components/Hero";
// import styled from "@emotion/styled";
import { ContentProvider } from "../../../contexts/ContentContext";
import InsightContent from "./InsightContent";

// const ContentTitle = styled.h1`
//   font-size: 4.8rem;
//   line-height: 60px;
//   background-color: ${props => props.theme.colors.lightPeach};
//   padding: 1rem;
//   @media (min-width: ${props => props.theme.breakpoints.md}) {
//     font-size: 6.5rem;
//     line-height: 80px;
//     padding: 6rem;
//   }
// `;

const Insight = ({ pageContext }) => {
  const {
    post: {
      id,
      databaseId,
      nodeType,
      HeroSection,
      featuredImage,
      categories,
      terms,
      tags,
      content,
      Insight,
      author,
      date,
      PageCptFields,
      PreFooter,
      seo,
      slug,
      status,
      title,
      uri,
    },
    previous,
    next,
  } = pageContext;
  return (
    <ContentProvider
      value={{
        id,
        databaseId,
        nodeType,
        HeroSection,
        featuredImage,
        categories,
        terms,
        tags,
        content,
        author,
        Insight,
        PageCptFields,
        PreFooter,
        seo,
        slug,
        status,
        title,
        uri,
        previous,
        next,
      }}
    >
      <Layout>
        <Hero
          data={{
            ...HeroSection,
            ...Insight,
            featuredImage: featuredImage?.node,
          }}
        />
        <InsightContent
          author={author}
          date={date}
          title={title}
          content={content}
        />
      </Layout>
    </ContentProvider>
  );
};

export default Insight;
