import React from "react";
import styled from "@emotion/styled";

import Descriptor from "../../components/Descriptor/index";
import Image from "../../components/image";
// import PageLink from "../../components/PageLink";

const AuthorCard = styled.div`
  font-family: ${props => props.theme.fonts.regular};

  .sectionWrapper {
    padding: 4rem 1rem;
    background-color: ${props => props.theme.colors.offWhite};
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: flex;
      padding: 5rem 6rem;
    }
  }

  .leftWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 23.275862069%;
      /* padding: 0 6rem; */
    }
  }

  .contentContainer {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 62.5%;
    }
    &--single {
      @media (min-width: ${props => props.theme.breakpoints.md}) {
        margin-right: 12.586206896%;
        margin-left: 23.189655172%;
      }
    }
  }

  .author-info {
    display: flex;
    margin: 4rem 0 2rem;
    align-items: center;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      margin: 1rem 0 2rem;
    }
  }

  .author-credentials {
    margin-left: 2rem;
  }

  .author-name {
    font-size: 2.5rem;
    line-height: 32px;
    letter-spacing: 0.75px;
    margin-bottom: 0.8rem;
    color: ${props => props.theme.colors.darkPink};
  }

  .author-title {
    font-size: 1.3rem;
    line-height: 23px;
    letter-spacing: 2.34px;
    text-transform: uppercase;
    color: ${props => props.theme.colors.darkGold};
  }

  .author-bio {
    font-size: 1.8rem;
    line-height: 29px;
    color: ${props => props.theme.colors.offBlack};
    margin-bottom: 3rem;
    max-width: 559px;
  }
`;

const AuthorImage = styled(Image)`
  width: 86px;
  height: 86px;
  border-radius: 50%;
`;

// const MoreLink = styled(PageLink)`
//   font-size: 1.8rem;
//   line-height: 25px;
//   letter-spacing: 2.7px;
//   text-transform: uppercase;
//   text-decoration: underline;
//   color: ${props => props.theme.colors.lighterPurple};
// `;

const InsightAuthor = ({ author }) => {
  // console.log(author);
  return (
    <AuthorCard>
      <div className="sectionWrapper">
        <div className="leftWrapper">
          <Descriptor
            title="about the author"
            color="#1A1A1A"
            alignment="left"
          />
        </div>
        <div className="contentContainer">
          <div className="author-info">
            <AuthorImage image={author.ACFUser.image} />
            <div className="author-credentials">
              <p className="author-name">{author.name}</p>
              <p className="author-title">{author.ACFUser.jobTitle}</p>
            </div>
          </div>
          <p className="author-bio">{author.ACFUser.shortBio}</p>
          {/* Removing more about link until author pages are utilized */}
          {/* <MoreLink to={author.uri}>more about</MoreLink> */}
        </div>
      </div>
    </AuthorCard>
  );
};

export default InsightAuthor;
