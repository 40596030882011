import React, { useContext } from "react";
import styled from "@emotion/styled";
import InsightAuthor from "./InsightAuthor";
import ContentContext from "../../../contexts/ContentContext";
import "../../scss/imported-wordpress-styles.scss";
import LeftArrowSvg from "../../icons/arrow-left.svg";
import RightArrowSvg from "../../icons/arrow-right.svg";
import PageLink from "../../components/PageLink";
import { css } from "@emotion/react";

const BlogSingle = styled.div`
  .mobile-only {
    display: block;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: none;
    }
  }
  .desktop-only {
    display: none;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: block;
    }
  }
  .sectionWrapper {
    background-color: ${props => props.theme.colors.lightPeach};
    padding: 4rem 1rem;
    font-family: ${props => props.theme.fonts.regular};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: flex;
      padding: 6rem;
    }
  }

  .leftWrapper {
    margin-bottom: 2rem;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 23.275862069%;
    }
  }

  .contentContainer {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 62.5%;
    }
    &--single {
      @media (min-width: ${props => props.theme.breakpoints.md}) {
        margin-right: 12.586206896%;
        margin-left: 23.189655172%;
      }
    }
  }

  .contentTitle {
    font-size: 4rem;
    line-height: 50px;
    color: ${props => props.theme.colors.offBlack};
    margin-bottom: 2rem;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 6.5rem;
      line-height: 80px;
    }
  }

  .authorName {
    font-size: 1.5rem;
    line-height: 29px;
    color: ${props => props.theme.colors.darkGold};
    margin-bottom: 3rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      color: ${props => props.theme.colors.darkRed};
    }
  }

  .contentInfo {
    display: flex;
    font-size: 1.3rem;
    line-height: 23px;
    letter-spacing: 2.34px;
    text-transform: uppercase;
    color: ${props => props.theme.colors.darkRed};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 1.6rem;
      line-height: 25px;
      flex-direction: column;
      margin-top: 1.15rem;
    }

    p {
      margin-right: 5px;
    }

    .content-dash {
      color: ${props => props.theme.colors.darkGold};
      @media (min-width: ${props => props.theme.breakpoints.md}) {
        display: none;
      }
    }

    .content-line {
      display: none;

      @media (min-width: ${props => props.theme.breakpoints.md}) {
        margin: 10px 0 15px;
        display: inline-block;
        width: 70px;
        border: 0.2px solid ${props => props.theme.colors.darkGold};
      }
    }
  }
`;

const Container = styled.div`
  color: ${props => props.theme.colors.offBlack};
  font-family: ${props => props.theme.fonts.regular};
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
  }

  h1 {
    font-size: 4.8rem;
    line-height: 60px;
    margin-bottom: 3rem;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 12.2rem;
      line-height: 132px;
    }
  }
  h2 {
    font-size: 4rem;
    line-height: 50px;
    margin-bottom: 3rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 6.5rem;
      line-height: 80px;
    }
  }
  h3 {
    font-size: 3.1rem;
    line-height: 38px;
    margin-bottom: 3rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 4.8rem;
      line-height: 60px;
    }
  }
  h4 {
    font-size: 2.5rem;
    line-height: 32px;
    margin-bottom: 3rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 3.1rem;
      line-height: 38px;
    }
  }
  p {
    font-size: 1.8rem;
    line-height: 29px;
    margin-bottom: 3rem;
  }
  figcaption {
    font-size: 1.5rem;
    line-height: 29px;
    color: ${props => props.theme.colors.darkGold};
  }
  a {
    color: ${props => props.theme.colors.lighterPurple};
    text-decoration: underline;
  }
`;

const AdjacentPostLinkContainer = styled.div`
  display: flex;
  font-size: 1.8rem;
  line-height: 29px;
  text-decoration: underline;
  text-underline-offset: 2px;
`;

const AdjacentPostLinkTextPrevious = styled.span`
  font-weight: bold;
  margin-left: 1rem;
`;
const AdjacentPostLinkTextNext = styled.span`
  font-weight: bold;
  margin-right: 1rem;
`;

const PreviousPostLink = styled(PageLink)`
  color: ${props => props.theme.colors.lighterPurple};
  display: flex;
  align-items: center;
`;

const NextPostLink = styled(PageLink)`
  color: ${props => props.theme.colors.lighterPurple};
  display: flex;
  align-items: center;
`;

const ArrowLeft = styled(LeftArrowSvg)`
  font-size: 1.8rem !important;
  height: 2.4rem !important;
`;

const ArrowRight = styled(RightArrowSvg)`
  font-size: 1.8rem !important;
  height: 2.4rem !important;
`;

const InsightContent = ({ author, date, title, content }) => {
  const context = useContext(ContentContext);
  return (
    <BlogSingle>
      <div className="sectionWrapper">
        <div className="leftWrapper">
          <div className="contentInfo">
            <p>
              {context?.categories?.nodes?.map(node => node?.name).join(", ")}
            </p>
            <p className="content-dash">&mdash;</p>
            <hr className="content-line" />
            <p>{date}</p>
          </div>
        </div>
        <div className="contentContainer">
          <h1 className="contentTitle">{title}</h1>
          <p className="authorName">By {author.node.name}</p>
          <Container dangerouslySetInnerHTML={{ __html: content }}></Container>
          <AdjacentPostLinkContainer
            // justify-content: space-between if there are both prev and next links
            // justify-content: flex-end if there is only a next link (push it to the right)
            // justify-content: unset if there is only a previous link (push it to the left)
            css={css`
              justify-content: ${context.previous && context.next
                ? "space-between"
                : context.next
                ? "flex-end"
                : "unset"};
            `}
          >
            {context.previous && (
              <PreviousPostLink to={context.previous.uri}>
                <ArrowLeft />
                <AdjacentPostLinkTextPrevious>
                  Previous Post
                </AdjacentPostLinkTextPrevious>
              </PreviousPostLink>
            )}
            {context.next && (
              <NextPostLink to={context.next.uri}>
                <AdjacentPostLinkTextNext>Next Post</AdjacentPostLinkTextNext>
                <ArrowRight />
              </NextPostLink>
            )}
          </AdjacentPostLinkContainer>
        </div>
      </div>
      <InsightAuthor author={author.node} />
    </BlogSingle>
  );
};

export default InsightContent;
